<template>
    <div>
        <mt :backType="1">
            <el-form :inline="true" align="left" label-width="120px">
                <el-form-item label="客户姓名">
                    <el-input readonly v-model="customer.customerName" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="客户电话">
                    <el-input readonly v-model="customer.customerPhone" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="支付方式">
                    <el-select v-model="customer.payType" style="width: 160px">
                        <el-option :value="1" label="现金"></el-option>
                        <el-option :value="2" label="银行卡"></el-option>
                        <el-option :value="3" label="微信"></el-option>
                        <el-option :value="4" label="支付宝"></el-option>
                        <el-option :value="5" label="其他"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="卡号">
                    <el-input readonly v-model="customer.customerCard" style="width: 160px"></el-input>
                </el-form-item>
                <br>
                <el-form-item label="收货人">
                    <el-input readonly v-model="customer.receiver" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收货人电话">
                    <el-input readonly v-model="customer.receiverPhone" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item label="收货地址">
                    <el-input readonly v-model="customer.receiverAddress" style="width: 430px"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="customer.remark" style="width: 160px"></el-input>
                </el-form-item>
                <el-form-item style="margin-left: 30px">
                    <el-button @click="orderOk" type="primary">确认下单</el-button>
                </el-form-item>

            </el-form>
        </mt>

        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <el-button @click="addGoods">添加商品</el-button>
            </div>
            <el-table border stripe :data="cart" class="funBtn">

                <el-table-column label="序号" type="index" width="80"></el-table-column>
                <el-table-column label="商品名称" prop="name"></el-table-column>
                <el-table-column label="货号" prop="name">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.itemNumber"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="参考零售价">
                    <template slot-scope="scope">
                        {{scope.row.price1 | fen2yuan}}
                    </template>
                </el-table-column>
                <el-table-column label="实际售价">
                    <template slot-scope="scope">
                        <el-form v-model="scope.row" :model="scope.row">
                            <el-input-number v-model="scope.row.tPrice" :controls="false" :precision="2"
                                             :min="0" @change="setTprice(scope)"></el-input-number>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column label="购买数量">
                    <template slot-scope="scope">
                        <el-input-number type="number" v-model="scope.row.count" :controls="false"
                                         :precision="0" @change="setCount(scope)"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="小计">
                    <template slot-scope="scope">
                        {{_sub(scope.row.count,scope.row.tPrice) | money }}
                    </template>
                </el-table-column>
                <el-table-column label="备注">
                    <template slot-scope="scope">
                        <span v-if="scope.row.tPrice==0">赠送</span>
                        <span v-if="(scope.row.tPrice!=0)&&(scope.row.count>0)">销售</span>
                        <span v-if="(scope.row.tPrice!=0)&&(scope.row.count<0)">退货</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click="removeFromCart(scope.$index)">移出购物车</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>

    import mt from "@/components/MainTop.vue"

    export default {
        components: {mt},
        name: "NewOrder",
        props: ['orderId'],
        data() {
            return {
                goodsMeta: {
                    name: "",
                    page: 0,
                    size: 10,
                    total: 0
                },
                customer: {},
                cart: [],
                goods: [],
                showGoods: true
            }
        },
        mounted() {
            let _this = this;
            if (this.orderId) {
                this.$http.get("/api/order/find", {params: {orderId: this.orderId, page: 1, size: 1}})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            if (rsp.data.data.length) {
                                _this.customer = rsp.data.data[0];
                                let _details = this.customer.details.map(c => {
                                    c.tPrice = c.price2 / 100;
                                    c.name = c.goodsName;
                                    c.id = c.goodId;
                                    return c;
                                });
                                this.cart = JSON.parse(JSON.stringify(_details));
                                let _cartGoods = JSON.parse(localStorage.getItem("cartGoods") || "{}");
                                if (_cartGoods) {
                                    //添加商品返回
                                    if (_cartGoods['customer']) {
                                        this.customer = _cartGoods.customer;
                                    }
                                    if (_cartGoods['cart']) {
                                        this.cart = _cartGoods['cart'];
                                    }
                                }
                                localStorage.removeItem("cartGoods");
                            }
                        }
                    });
            }
        },

        methods: {
            removeFromCart(row) {
                let a = JSON.parse(JSON.stringify(this.cart));
                a.splice(row, 1);
                this.cart = a;
            },
            orderOk() {//确认订单
                let _this = this;
                localStorage.removeItem("cartGoods");
                let param = {
                    id: this.customer.id,
                    payType: this.customer.payType,
                    receiver: this.customer.receiver,
                    receiverPhone: this.customer.receiverPhone,
                    receiverAddress: this.customer.receiverAddress,
                    remark: this.customer.remark,
                    goods: this.cart.map(a => {
                        let c = {}
                        c.goodsId = a.id;
                        c.itemNumber = a.itemNumber;
                        c.price1 = a.price1;
                        c.price2 = _this.yuan2fen(a.tPrice);
                        c.count = a.count;
                        return c;
                    })
                }
                if (!param.goods.length) {
                    this.$message.error("购物车内没有商品");
                    return;
                }
                if (param.goods.find(c => !c.count)) {
                    this.$message.error("购买数量必填且不能为零");
                    return;
                }
                this.$http.patch("/api/order/edit/" + param.id, param)
                    .then((rsp) => {
                        if (rsp.data.status == 200) {
                            this.$confirm('下单成功', '提示', {
                                confirmButtonText: '留在本页面',
                                cancelButtonText: '返回上一页',
                                type: 'success'
                            }).then(() => {

                            }).catch(() => {
                                this.$router.go(-1);
                            });
                        }
                    })
                    .catch((rsp) => {
                        this.$message.error(rsp.response.data.message);
                    })
            },
            addGoods() {
                let _this = this;
                localStorage.setItem("cartGoods", JSON.stringify({customer: _this.customer}));
                this.$router.push({name: 'AddGoods', params: {form: _this.cart}})
            },
            // _goodsToAdd(arr) {
            //     let ids = this.cart.map(c => c.id);
            //     let arrToAdd = arr.filter(c => !ids.includes(c.id)).map(c => {
            //         c.tPrice = this.fen2yuan(c.price2);
            //         c.payType = 1;
            //         c.goodId = c.id;
            //         return c;
            //     });
            //     if (arrToAdd.length) {
            //         let _a = this.cart.concat(arrToAdd);
            //         this.cart = JSON.parse(JSON.stringify(_a));
            //     }
            // },
            _sub(a, b) {
                return a * b;
            },
            setTprice(scope) {
                console.log(scope.row)
                this.$forceUpdate();
            },
            setCount(scope) {
                console.log(scope.row)
                this.$forceUpdate();
            }
        }
    }
</script>

<style scoped>
    .mt .el-form-item {
        margin: 18px auto
    }

    .mt .el-form {
        padding: 18px 0;
    }
</style>
